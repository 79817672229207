@font-face {
    font-family:"Univers Next W01";
    src:url("../fonts/63aa74f9-a724-4074-85c7-4167c0448227.eot?#iefix");
    src:url("../fonts/63aa74f9-a724-4074-85c7-4167c0448227.eot?#iefix") format("eot"),url("../fonts/97c0f83d-39ac-412b-9d8a-d98208c0ce8d.woff2") format("woff2"),url("../fonts/fc09acb4-f628-4ba0-b648-d2ca30265b20.woff") format("woff"),url("../fonts/3cb4004c-2eea-45d4-a0b5-7af6f817c451.ttf") format("truetype"),url("../fonts/4d6ce461-aa11-472f-af6d-7466479534e1.svg#4d6ce461-aa11-472f-af6d-7466479534e1") format("svg");
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family:"Univers Next W01";
    src:url("../fonts/8c7fe4ab-375c-4be5-bba8-2c4d83ac807a.eot?#iefix");
    src:url("../fonts/8c7fe4ab-375c-4be5-bba8-2c4d83ac807a.eot?#iefix") format("eot"),url("../fonts/b37595d7-d2c2-465d-81bd-f5bc289fc565.woff2") format("woff2"),url("../fonts/88e0673d-3db0-4931-ae03-8a4a6eb1bbe6.woff") format("woff"),url("../fonts/957860fc-f472-47cc-afcb-6305b3455894.ttf") format("truetype"),url("../fonts/6b3014c0-a599-4891-a930-63fb35a38eae.svg#6b3014c0-a599-4891-a930-63fb35a38eae") format("svg");
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family:"Univers Next W01";
    src:url("../fonts/6a8ecc96-ca25-462d-b743-4d3a0a56488b.eot?#iefix");
    src:url("../fonts/6a8ecc96-ca25-462d-b743-4d3a0a56488b.eot?#iefix") format("eot"),url("../fonts/d9a4647f-eae6-4f43-9bea-905996bf7d4c.woff2") format("woff2"),url("../fonts/cb3c8591-6f6f-498c-b8e7-ee3a9d0a7547.woff") format("woff"),url("../fonts/65a9b4cb-e63b-452a-bea2-bd37cc2b10f6.ttf") format("truetype"),url("../fonts/a7a08b81-ee91-4e2b-b0ac-2a87a44e0eda.svg#a7a08b81-ee91-4e2b-b0ac-2a87a44e0eda") format("svg");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family:"Univers Next W01";
    src:url("../fonts/1704714d-e1a2-4c14-91b2-279e94ba5706.eot?#iefix");
    src:url("../fonts/1704714d-e1a2-4c14-91b2-279e94ba5706.eot?#iefix") format("eot"),url("../fonts/c21cb708-eacd-40d7-931d-13b4b66fec3b.woff2") format("woff2"),url("../fonts/00f88057-c6a6-448b-be2e-e8bc4a9c28d4.woff") format("woff"),url("../fonts/4e503d23-4bcf-4a2f-b45f-846380d958c9.ttf") format("truetype"),url("../fonts/38b5b039-d625-4f03-8edd-4faadd911c24.svg#38b5b039-d625-4f03-8edd-4faadd911c24") format("svg");
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family:"Univers Next W01";
    src:url("../fonts/07e1641d-6281-4461-827c-f3940fa0d811.eot?#iefix");
    src:url("../fonts/07e1641d-6281-4461-827c-f3940fa0d811.eot?#iefix") format("eot"),url("../fonts/e8921ecc-af32-4c3d-9636-dd82b4225e40.woff2") format("woff2"),url("../fonts/20f7bd07-85c9-4687-ab29-0b87584842ff.woff") format("woff"),url("../fonts/2df6d56e-7f31-4175-88df-47d374d8bb60.ttf") format("truetype"),url("../fonts/9ca9081d-10de-4848-b53a-40ceaa62b2b2.svg#9ca9081d-10de-4848-b53a-40ceaa62b2b2") format("svg");
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family:"Univers Next W01";
    src:url("../fonts/7af2167c-efe7-44d7-b2e1-6fdeeaa927c9.eot?#iefix");
    src:url("../fonts/7af2167c-efe7-44d7-b2e1-6fdeeaa927c9.eot?#iefix") format("eot"),url("../fonts/8e26dc86-9708-4a36-89fb-a4416a5b9f5c.woff2") format("woff2"),url("../fonts/c3b66848-d40c-458a-a977-7398cb9c1c82.woff") format("woff"),url("../fonts/ea870cc2-efce-428c-af01-d00573ee4b0e.ttf") format("truetype"),url("../fonts/650a08a0-413e-47e4-a083-617a54b35ab0.svg#650a08a0-413e-47e4-a083-617a54b35ab0") format("svg");
    font-weight: 700;
    font-style: italic;
}

@font-face {
    font-family:"FF Meta Serif W01";
    src:url("../fonts/b95879a4-6677-40bf-9528-ffecf9b247e4.eot?#iefix");
    src:url("../fonts/b95879a4-6677-40bf-9528-ffecf9b247e4.eot?#iefix") format("eot"),url("../fonts/540baff3-1dae-4349-b607-d71e6a8d5a3e.woff2") format("woff2"),url("../fonts/329d96cf-0721-417a-9888-cc6b095163c3.woff") format("woff"),url("../fonts/c46c5707-21ab-4331-99d5-197ac889ffc7.ttf") format("truetype"),url("../fonts/0bab2c34-452c-4fa4-9bc7-512deb05bfc3.svg#0bab2c34-452c-4fa4-9bc7-512deb05bfc3") format("svg");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family:"FF Meta Serif W01";
    src:url("../fonts/a496a7b4-a708-45b5-be88-8059f43118cd.eot?#iefix");
    src:url("../fonts/a496a7b4-a708-45b5-be88-8059f43118cd.eot?#iefix") format("eot"),url("../fonts/3eed0fa3-246d-45d8-83c6-1b3bb5fad3c3.woff2") format("woff2"),url("../fonts/a0df4306-2f97-461f-9069-7d113bf54b22.woff") format("woff"),url("../fonts/b67726e3-8ffa-45d6-9bee-f1839d34d9f4.ttf") format("truetype"),url("../fonts/7ed8b236-2705-4261-94b6-6e3aa4d7ef49.svg#7ed8b236-2705-4261-94b6-6e3aa4d7ef49") format("svg");
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family:"FF Meta Serif W01";
    src:url("../fonts/7e08fa85-157b-49a7-a0b3-4e57ecfc81ae.eot?#iefix");
    src:url("../fonts/7e08fa85-157b-49a7-a0b3-4e57ecfc81ae.eot?#iefix") format("eot"),url("../fonts/05ebb84f-7132-4bba-a010-01a2fb46795b.woff2") format("woff2"),url("../fonts/38708e4e-3137-4130-adcc-a00a0931bfce.woff") format("woff"),url("../fonts/ea2e8efb-dc49-454e-aec7-8e28be819fd2.ttf") format("truetype"),url("../fonts/45eddf24-318c-4526-9a7b-c8aa8e99d408.svg#45eddf24-318c-4526-9a7b-c8aa8e99d408") format("svg");
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family:"FF Meta Serif W01";
    src:url("../fonts/f6a1952f-9c32-4586-b636-72963502ff6c.eot?#iefix");
    src:url("../fonts/f6a1952f-9c32-4586-b636-72963502ff6c.eot?#iefix") format("eot"),url("../fonts/f3407fb8-4405-45bd-92d8-bb83f07ecac1.woff2") format("woff2"),url("../fonts/d9742ea6-514b-4266-a86c-9dae1d1a61db.woff") format("woff"),url("../fonts/96597e5e-c2de-4f9a-bdc9-e27ac2d899f3.ttf") format("truetype"),url("../fonts/7536ec60-7254-4f22-8cc6-bf69f4ea6fc4.svg#7536ec60-7254-4f22-8cc6-bf69f4ea6fc4") format("svg");
    font-weight: 700;
    font-style: italic;
}


// =============================================================================
// String Replace
// =============================================================================

@function str-replace($string, $search, $replace: "") {
    $index: str-index($string, $search);

    @if $index {
        @return str-slice($string, 1, $index - 1) + $replace + str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
    }

    @return $string;
}

h2 {
    font-size: 20pt;
    margin: 0 0 50px;
    color: get-color(primary);
    font-weight: 200;

    @include breakpoint(large) {
        font-size: 40px;
    }
}
