.image-cards {
    margin-top: 80px;
    margin-bottom: 90px;
}

.image-card {
    border: 1px solid $cool-grey;
    border-radius: 5px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 60px;

    img {
        display: block;
        width: 100%;
        height: auto;
    }

    p {
        margin: 20px 30px;
        color: get-color(secondary);
        font-size: 16px;
        font-weight: 200;

        @include breakpoint(large) {
            font-size: 18px;
        }
    }

    a {
        color: get-color(secondary);
        margin: 0 30px 30px;
        font-weight: bold;

        &:hover {
            color: get-color(primary);
        }
    }
}