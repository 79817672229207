.homepage-banner {
    margin-bottom: 40px;

    /* Bug in IE11 */
    width: 100%;
    max-width: 1440px;

    &::before {
        display: block;
    }

    @include breakpoint(medium) {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 90px;
    }

    @include breakpoint(medium only){
        margin-bottom: 30px;
    }

    .homepage-banner-left-image {
        position: relative;
        padding: 0;
        margin-bottom: 3px;
        background-repeat:no-repeat;
        background-size:cover;

        @include breakpoint(medium) {
            margin-bottom: 0;
            border-right: 3px solid $white;
        }

        img {
            width: 100%;
            min-height: 100%;
        }

        .text-overlay {
            background: $white;
            position: absolute;
            bottom: 20px;
            margin: 0 20px;
            width: calc(100% - 40px);
            color: get-color(secondary);
            font-size: 22px;
            padding: 25px;
            line-height: 28px;
            font-weight: 200;

            @include breakpoint(large) {
                background: none;
                color: $white;
                font-size: 38px;
                line-height: 1.1;
                letter-spacing: -1.1px;
                left: 60px;
                margin: 0;
                padding: 0;
                width: 70%;
                top:  220px;
            }

            @include breakpoint(xlarge){
                font-size: 55px;
                line-height: 1;
            }

            .bold-underline {
                font-weight: bold;
                text-decoration: underline;
                text-decoration-color: get-color(primary);
            }
        }

        .scroll-down {
            position: absolute;
            color: $white;
            bottom: 50px;
            left: 60px;
            font-size: 18px;
            font-weight: bold;
        }

        a {
            color: $white;
            text-decoration: none;

            &:hover {
                color: $white;
                text-decoration: none;
            }
        }
    }

    .homepage-banner-right-image {
        padding: 0;

        img {
            width: 100%;
            height: auto;
        }

        .text {
            background: $pale-grey-two;
            padding: 20px 20px 30px;
            font-size: 20px;
            color: get-color(secondary);
            font-weight: 200;

            @include breakpoint(large) {
                font-size: 24px;
            }

            a {
                color: get-color(secondary);
                font-size: 14px;
                font-weight: bold;

                &:hover {
                    color: get-color(primary);
                }
            }
        }
    }
}