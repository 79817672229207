/**
 * Full Width
 */
@mixin full-width() {
    width: 100% !important;
    max-width: 100% !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
}

@mixin modal-init-styles($background-color, $color, $top){
    position: fixed;
    top: $top;
    background-color: $background-color;
    color: $color;
    z-index: 100;

    @include breakpoint(large) {
        right: 0;
    }

    .fa-close {
        position: absolute;
        right: 20px;
        top: 20px;
        color: get-color(primary);
    }
}

@mixin action-button() {
    padding: $menu-item-padding;
    border: 2px solid get-color(primary);
    border-bottom: 2px solid get-color(primary);
    font-weight: bold;
    border-radius: 5px;
    background-color: transparent;
    color: $dusky-blue;

    &:hover {
        color: $white;
        text-decoration: none;
        border: 2px solid get-color(primary);
        border-bottom: 2px solid get-color(primary);
        background: get-color(primary);
    }
}

@mixin secondary-button() {
    padding: $menu-item-padding;
    border: 2px solid $dusky-blue;
    border-bottom: 2px solid $dusky-blue;
    font-weight: bold;
    border-radius: 5px;
    background-color: transparent;
    color: $dusky-blue;

    &:hover {
        color: $white;
        text-decoration: none;
        border: 2px solid $dusky-blue;
        border-bottom: 2px solid $dusky-blue;
        background: $dusky-blue;
    }
}

@mixin link-styles(){
    text-decoration: underline;
}
