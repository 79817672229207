.header {

    .header-inner {
        background: $white;
        box-shadow: 0px 4px 4px -2px rgba(0, 0, 0, 0.08);
        z-index: 2;
        padding: 0;
    }

    .page-title {
        width: 50%;
        margin: 0 0 0 0.5em;
        padding-top: 5px;

        @include breakpoint(large) {
            width: 270px;
            line-height: 1;
            display: block;
            padding-top: 10px;
        }

        @include breakpoint(xxlarge){
            margin: 0;
        }

        img {
            width: 100%;
            height: auto;
            display: block;

            @include breakpoint(medium only){
                width: 75%;
            }
        }
    }

    .top-bar {
        position: relative;
        max-width: 90em;

        @include breakpoint(large) {
            padding: 30px 0;
        }

        @include breakpoint(medium only){
            padding: 1em;
        }
    }

    .menu-icon {
        @include breakpoint(large){
            display: none;
        }
    }

    .top-bar-title {
        width: 100%;
        padding: 0;

        @include breakpoint(large) {
            float: left;
            width: auto;
        }

        span {
            display: inline-block;
            margin-top: 5px;
        }
    }

    @include breakpoint(medium down) {
        #responsive-menu {
            @include full-width();
            @include mui-slide(in, $duration: 0.1s);
            @include mui-slide(out, right, $duration: 0.1s);


            position: absolute;
            top: 100%;
            width: calc(100% + 20px);
            left: 0;
            height: calc(100vh - 45px);
            z-index: 2 !important;

            .top-bar-right {
                height: 100%;
                width: 100%;
            }

            .menu {
                background: $dusky-blue;
                padding-top: 20px;
                height: 100%;

                li {
                    padding: 0 map_get($grid-column-gutter, small);
                    margin: 0;

                    a {
                        color: $white;
                        font-size: 18px;
                        line-height: 40px;
                        padding: 0;
                        border-bottom: 1px solid $white;
                    }

                    &.order-book {
                        a {
                            border: none;
                            border-bottom: 1px solid $white;
                            text-align: left;
                            font-weight: bold;
                            background-color: transparent;
                        }
                    }

                    &.social-links {
                        position: absolute;
                        left: 0;
                        bottom: 30px;

                        a {
                            display: inline-block;
                            color: $dusky-blue;
                            height: 30px;
                            width: 30px;
                            line-height: 32px;
                            background: $white;
                            border-radius: 50%;
                            padding-left: 8px;
                            margin-right: 20px;
                        }
                    }
                }
            }
        }
    }

    @include breakpoint(large) {
        #responsive-menu {
            .menu {
                li {
                    padding: $menu-item-padding;

                    &:last-child {
                        padding-right: 2em;
                    }

                    a {
                        font-size: 18px;
                        color: get-color(secondary);
                        padding: 0 0 1px;
                        border-bottom: 1px solid transparent;

                        &.active,
                        &:hover {
                            border-bottom: 1px solid get-color(primary);
                        }

                    }
                }

                .order-book {
                    padding: 0;

                    a {
                        @include action-button();
                    }
                }
            }
        }
    }

    @include breakpoint(xxlarge){
        #responsive-menu {
            .menu {
                li {
                    &:last-child {
                        padding-right: 0;
                    }
                }
            }
        }
    }
}
