.news-feed {
    padding-bottom: 35px;
    margin-bottom: 45px;

    @include breakpoint(medium down) {
        background: $pale-grey-two;
    }

    @include breakpoint(large) {
        padding-bottom: 0;
        margin-bottom: 0;
    }

    h2 {
        margin: 45px 0 15px;

        @include breakpoint(large) {
            margin: 0 0 40px;
        }
    }

    p {
        margin-bottom: 22px;
        font-size: 18px;
        line-height: 26px;
        font-weight: 200;

        @include breakpoint(large) {
            font-size: 24px;
            line-height: normal;
            margin-bottom: 25px;
        }
    }

    a {
        color: get-color(secondary);
        font-weight: bold;
        font-size: 14px;
        margin-bottom: 25px;

        &:hover {
            color: get-color(primary);
        }

        @include breakpoint(large) {
            font-weight: normal;
            font-size: 20px;
            margin-bottom: 50px;
        }
    }

    hr {
        background: $silver;
        margin-bottom: 16px;

        @include breakpoint(large) {
            margin-bottom: 50px;
        }
    }
}