.footer {
    background: $dusky-blue;
    color: $white;
    font-weight: $global-weight-light;
    font-size: 14px;
    padding-bottom: 50px;

    a {
        color: $white;
        text-decoration: underline;

        &:hover {
            text-decoration: none;
        }
    }

    .footer-left {
        padding-right: 60px;
    }

    @include breakpoint(large) {
        padding-bottom: 0;
        font-size: 16px;
    }

    .footer-logos {
        padding: 50px 0 60px;

        @include breakpoint(large) {
            padding: 100px 0 120px;
        }

        img {
            width: 65%;
        }

        p {
            line-height: 1.4;
            margin: 30px 0 0;

            @include breakpoint(large) {
                width: 80%;
                margin: 30px auto 0;
            }
        }
    }

    .bank-of-america {
        background: $dark-grey-blue;
        padding-top: 50px;
        padding-bottom: 50px;

        @include breakpoint(large) {
            text-align: left;
            padding-top: 100px;

            p {
                margin-left: 10px;
            }
        }

        img {
            margin-bottom: 25px;

            @include breakpoint(large) {
                margin: 0 auto 25px;
                display: block;
            }

            @include breakpoint(medium) {
                max-height: none;
            }

            @include breakpoint(small) {
                max-height: 40px;
            }
        }
    }

    .footer-links {
        padding-top: 45px;
        padding-bottom: 40px;

        @include breakpoint(large) {
            padding-top: 100px;
            padding-bottom: 140px;
        }

        ul {
            list-style: none;
            margin: 0;

            li {
                padding: 0;

                @include breakpoint(large) {
                    line-height: 35px;
                }

                // Override motion-ui default
                &:last-child {
                    float: left;
                }
            }

            a {
                font-weight: normal;
                text-decoration: none;

                &:hover {
                    text-decoration: underline;
                }

                @include breakpoint(large) {
                    font-weight: bold;
                }
            }
        }
    }

    .footer-copyright {
        @include breakpoint(large) {
            padding-bottom: 70px;
        }

        .divider {
            width: 100%;
            height: 2px;
            background: $dark-grey-blue-two;
            margin-bottom: 40px;
        }

        .social-links {
            margin-bottom: 25px;

            @include breakpoint(large) {
                text-align: right;
            }

            a {
                color: $dusky-blue;
                font-size: 16px;
                background: $white;
                height: 30px;
                width: 30px;
                display: block;
                float: left;
                margin-right: 20px;
                border-radius: 50%;
                line-height: 32px;
                text-align: center;

                &:hover {
                    background: get-color(primary);
                }

                @include breakpoint(large) {
                    float: none;
                    display: inline-block;

                    &:last-of-type {
                        margin: 0;
                    }
                }
            }
        }

        .copyright {
            span {
                margin-bottom: 15px;
                display: block;

                @include breakpoint(large) {
                    margin: 0 20px 0 0;
                    display: inline;
                    font-size: 18px;
                }
            }
        }

        .policies {
            a {
                margin-right: 20px;
                text-decoration: none;

                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }
}