.homepage-video {
    margin-bottom: 50px;

    @include breakpoint(large) {
        margin-bottom: 65px;
    }

    h2 {
        margin-bottom: 8px;
    }

    p {
        margin-bottom: 30px;
        font-weight: 200;

        @include breakpoint(large) {
            font-size: 20px;
            line-height: 34px;
        }
    }

    .video {
        @include breakpoint(large) {
            position: relative;
        }
        img {
            width: 100%;
            height: auto;
            margin: 0 0 20px;
        }
    }

    .video-button {
        border: 1px solid get-color(primary);
        display: block;
        width: 100%;
        line-height: 44px;
        text-align: center;
        color: get-color(secondary);
        font-weight: bold;
        border-radius: 5px;

        @include breakpoint(large) {
            position: absolute;
            bottom: 60px;
            right: 60px;
            width: auto;
            background: $white;
            border: none;
            padding: 0 40px;

            &:hover {
                color: $white;
                background: get-color(primary);
            }
        }
    }
}