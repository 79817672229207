.page-nav {
    background: $pale-grey-two;
    margin: 0 0 50px;

    .desktop-navigation {
        list-style: none;
        padding: 0;
        margin: 0;
        display: block;

        li {
            display: block;
            float: left;
            margin: 0 20px;

            a {
                color: get-color(secondary);
                text-transform: uppercase;
                size: 15px;
                line-height: 54px;
                height: 54px;
                font-weight: bold;
            }
        }
    }

    @include breakpoint(medium down) {
        padding: 0;
        background: none;
        margin: 0 0 35px;

        .hoverline {
            display: none !important;
        }

        .accordion {
            background: $pale-grey;

            .accordion-title {
                font-size: 10px;
                text-transform: uppercase;
                color: $dark-grey-blue;
                font-weight: bold;

                &::before {
                    font-size: 24px;
                    font-weight: normal;
                    line-height: 14px;
                }
            }

            .accordion-content {
                background: $pale-grey;

                ul {
                    list-style: none;
                    margin: 0;
                    padding: 0;
                    li {
                        border-bottom: 1px solid $silver;

                        &:last-of-type {
                            border-bottom: none;
                        }

                        a {
                            font-size: 10px;
                            text-transform: uppercase;
                            color: $dark-grey-blue;
                            font-weight: bold;
                            line-height: 44px;
                        }
                    }
                }
            }
        }
    }
}
