$form-popup-width: 40%;

#order-book-popup {
  overflow: scroll;
  z-index: 100;

  .row {
    margin: 0.35em 0;
  }

  .callout.alert {
    margin-top: 1rem;
  }

  // Params: $background-color, $color, $top
  @include modal-init-styles($white, $dusky-blue, 0);
  height: 100%;
  padding: 3em 1.6em;

  &.position-right.is-transition-push:after {
    box-shadow: none;
  }

  form .checkbox-group label {
    font-weight: normal;
  }

  input, select {
    margin-top: 0.3em;
  }

  .no-capitalize {
    text-transform: none;
  }

  .quantity-info {
    margin-bottom: 1rem;
  }

  input[type='checkbox'] {
    margin-top: 0.4em;
    transform: scale(1.2);
  }

  .address-fields-wrapper {
    display: none;
  }

  .other-organization-type {
    display: none;
  }
  .other-referral {
    display: none;
  }

  #order-pdf-checkbox {
    margin-bottom: 0;
  }

  .book-email {
    @include link-styles();
    font-weight: normal;
  }

  @include breakpoint(medium down) {
    -webkit-overflow-scrolling: touch;

    // Repeat btn styles for hover to disable default hover effect
    #order-book-popup #submit-book-order-btn:hover {
      padding: $menu-item-padding;
      border: 2px solid get-color(primary);
      border-bottom: 2px solid get-color(primary);
      font-weight: bold;
      border-radius: 5px;
      background-color: transparent;
      color: $dusky-blue;
    }
  }

  @include breakpoint(small only) {
    width: 100%;

    h1 {
      font-size: 1.8em;
    }
    p {
      font-size: 1.1em;
    }
  }

  @include breakpoint(large) {
    height: 100%;

    .is-open-left {
      transform: translateX(-$form-popup-width);
    }

    .off-canvas.position-left {
      width: $form-popup-width;
      left: -$form-popup-width;
    }

    p {
      font-size: 1.1em;
      margin-bottom: 2em;
    }

    form label {
      font-size: 1em;
    }
  }

  button, #submit-book-order-btn {
    width: 100%;
    margin: 1.8em 0 0;
    @include action-button();
  }

  .fa-close {
    font-size: 1.8em;
    padding: 0.2em;
    cursor: pointer;
  }

  @include mui-slide(in, $duration: 0.1s);
  @include mui-slide(out, right, $duration: 0.1s);
}
