.homepage-book {
    margin-bottom: 65px;

    .book-cover {
        margin-bottom: 25px;

        @include breakpoint(medium down) {
            padding: 0;
        }

        img {
            width: 100%;
            height: auto;

            @include breakpoint(large) {
                /* width: 65%; */
                margin: 0 auto;
                display: block;
            }

            @include breakpoint(medium only){
                display: block;
                margin: 0 auto;
            }
        }
    }

    .text {
        h2 {
            margin-bottom: 8px;
            line-height: 1.1;
        }

        a {
            text-decoration: underline;
            font-weight: bold;
            color: get-color(secondary);

            &.action-link {
                display: block;
                font-size: 20px;
                margin-bottom: 15px;

                @include breakpoint(large) {
                    display: inline-block;
                    margin-right: 30px;
                }
            }

            &:hover {
                text-decoration: none;
            }
        }

        p {
            @include breakpoint(large) {
                margin-bottom: 60px;
                font-size: 20px;
                font-weight: 200;
            }
        }
    }
}