.twitter-feed {
    h2 {
        margin-bottom: 20px;

        @include breakpoint(large) {
            margin-bottom: 0;
        }

        i {
            color: $silver;
        }
    }

    p {
        font-size: 15px;
        margin-bottom: 35px;

        @include breakpoint(large) {
            font-size: 18px;
            margin-bottom: 55px;
        }
    }

    .tweet-container {
        margin-bottom: 30px;

        @include breakpoint(large) {
            margin-bottom: 0;
        }

        hr {
            margin: 40px 0;
        }

        img {
            border-radius: 5px;
            display: block;
            width: 100%;
            height: auto;
        }

        span {
            display: block;
        }

        .name {
            text-transform: uppercase;
            font-weight: bold;
            font-size: 12px;

            @include breakpoint(large) {
                font-size: 15px;
                margin-bottom: 5px;
            }
        }

        .tweet {
            font-size: 15px;
            line-height: 24px;
            margin-bottom: 10px;

            @include breakpoint(large) {
                font-size: 18px;
                margin-bottom: 15px;
            }

        }

        .timestamp {
            font-size: 10px;
            color: $dusky-blue;

            @include breakpoint(large) {
                font-size: 15px;
            }
        }
    }
}