/*
 * Global Styles
 */

.deep-link-offset {
  margin-top: 11em;
}

.content-text {
  a:focus {
    outline-width: 0;
  }
 }

.book-form-opened {
  overflow: hidden;
  height: 100%;

  .js-off-canvas-overlay.is-visible {
    background-color: #000;
    opacity: 0.5;
    z-index: 5;
  }
}


form {
  h1 {
    line-height: 1.2;
    text-transform: capitalize;
  }

  label {
    text-transform: capitalize;
    color: $dusky-blue;
    font-weight: bold;
    line-height: 1.3;

    span {
      text-transform: none;
    }

    .asterisk {
      color: get-color(primary);
    }
  }
}

.callout {
  margin-bottom: 0;

  .fa-close {
    font-size: 2em;

    position: absolute;
    right: 20px;
    top: 20px;
    color: $white;
  }
}

.order-book.callout {
  background-color: get-color(primary);
  font-size: 19px;
  color: $white;

  h2 { color: $white; padding-right: 1em; }

  a {
    color: $white;
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }
}

p {
  font-size: 16px;
  line-height: 24px;
}
