.events {
    position: relative;

    h2 {
        margin-bottom: 30px;
    }

    @include breakpoint(medium only){
        h2 {
            margin-bottom: 24px;
        }
    }
}

.event-card {
    border: 1px solid $cool-grey;
    border-radius: 5px;
    padding: 0;
    margin: 0 0 40px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: hidden;

    @include breakpoint(large) {
        margin: 0 0 155px;
    }

    &:hover {
        border-top: 6px solid get-color(primary);
        border-radius: 0 0 5px 5px;

        .top {
            padding: 30px 30px 0 30px;
        }
    }

    .top {
        padding: 35px 30px 0 30px;

        .date {
            font-size: 12px;
            font-weight: bold;
            text-transform: uppercase;
            color: get-color(secondary);
        }

        .time-and-location {
            font-size: 12px;
            text-transform: uppercase;
            color: $steel;
        }

        .description {
            font-size: 20px;
            line-height: 28px;
            font-weight: 400;
            color: get-color(secondary);
            margin: 10px 0 50px;
            font-weight: 200;

            @include breakpoint(large) {
                font-size: 22px;
                line-height: 30px;
            }
        }
    }

    .buttons {
        border-top: 1px solid $cool-grey;
        display: flex;

        a {
            width: 50%;
            display: block;
            text-align: center;
            height: 44px;
            line-height: 44px;
            color: get-color(secondary);
            font-weight: bold;
            font-size: 14px;

            &:first-of-type {
                border-right: 1px solid $cool-grey;
            }

            &:hover {
                background: $pale-grey;
                color: get-color(primary);
            }

            @include breakpoint(large) {
                font-size: 16px;
                line-height: 60px;
                height: 60px;
            }
        }
    }
}

.view-all-events {
    border: 1px solid $cool-grey;
    border-radius: 5px;
    display: block;
    width: 100%;
    height: 44px;
    line-height: 44px;
    color: get-color(secondary);
    font-weight: bold;
    font-size: 14px;
    text-align: center;
    margin: 0 0 40px;
}

.view-all-events-top {
    margin: 23px 0 0;
    font-size: 18px;
    font-weight: bold;
    color: get-color(secondary);
}

#share-popup {

     // Params: $background-color, $color, $top
    @include modal-init-styles($dusky-blue, $white, 20%);
    padding: 20px 30px 50px;

    h3 {
        font-size: 22px;

        @include breakpoint(large) {
            font-size: 40px;
        }
    }

    p {
        font-size: 15px;
        margin-bottom: 30px;

        @include breakpoint(large) {
            font-size: 18px;
        }
    }

    a {
        display: inline-block;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        margin-right: 20px;
        background: $white;
        color: $dusky-blue;
        text-align: center;
        line-height: 30px;

        &:hover {
            background: get-color(primary);
        }

        .fa {
            line-height: 1.8;
        }
    }

    .fa-close {
        cursor: pointer;
    }

}
