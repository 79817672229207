.page-title-banner {
    position: relative;
    padding: 0;

    img {
        width: 100%;
        height: auto;
    }

    h1 {
        margin: -11px 0 0;
        padding: 0;
        position: absolute;
        top: 50%;
        padding: 0 20px;
        font-weight: bold;
        color: $white;
        font-size: 22px;

        @include breakpoint(medium only) {
            font-size: 35px;
            line-height: 40px;
        }

        @include breakpoint(large) {
            font-size: 45px;
            line-height: 48px;
            margin: -22px 0 0;
            padding: 0 60px;
        }
    }
}