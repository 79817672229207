.page-nav {
  @include breakpoint(small only){
    margin-bottom: 0;
  }
}

.page-nav.blog, .page-nav.news, .page-nav.oia {
  a {
    font-weight: normal !important;
  }

  a.active {
    color: get-color(primary);
    border-bottom: 1px solid get-color(primary);
    font-weight: bold !important;
  }
}

.blog-wrapper, .news-wrapper, .oia-wrapper, .chapter-wrapper {
  .columns {
    margin: 1em 0 2.5em;

    &.social-media-column {
      margin: 2em 0 2.5em;
    }
  }

  h2 a {
    color: get-color(primary);
  }

  img {
    border-radius: 50%;
    width: 4em;
    height:  auto;
    margin: 0.5em 0 0.8em;
  }

  .author-name, .location {
    margin-bottom: 0;
  }

  .date {
    text-transform: uppercase;
    font-weight: bold;

    @include breakpoint(small only){
      margin-bottom: 0.6rem;
      font-size: 0.8rem;
    }
  }

  .author-name {
     font-weight: bold;
  }
  .location {
     color: $steel;

     @include breakpoint(small only){
        margin-bottom: 1rem;
     }
  }

  .article-title {
    line-height: 1.1;
    margin-bottom: 1.3rem;
    letter-spacing: -0.02em;

    @include breakpoint(small only){
      font-weight: bold;
    }
  }

  .article-summary {
    margin-bottom: 1.7rem;
  }

  .read-more {
    color: get-color(primary);
    font-weight: bold;

    .fa {
      color: get-color(primary);
      font-size: 0.8em;
    }

    @include breakpoint(small only){
      color: get-color(primary);
    }
  }

  .load-more-posts {
    padding: $menu-item-padding;
    border: 2px solid get-color(primary);
    border-bottom: 2px solid get-color(primary);
    font-weight: bold;
    border-radius: 5px;
    color: get-color(secondary);
    font-size: 1.2em;

    &:hover {
        color: $white;
        text-decoration: none;
        border: 2px solid get-color(primary);
        border-bottom: 2px solid get-color(primary);
        background: get-color(primary);
    }

    @include breakpoint(small only){
      width: 100%;
    }
  }

  hr {
    margin: 0 auto 2rem;

    @include breakpoint(small only){
      margin: 0 1.25rem;
    }
  }

  .follow-us-header {
    font-size: 1em;
    text-transform: uppercase;
    font-weight: bold;
    margin-bottom: 1.5em;
  }

  .social-media-wrapper {
    margin: 0 0 3em;
  }

  .social-links {
    margin-right: 0.7em;
    background-color:  $steel;
    width: 45px;
    height: 45px;
    padding: 11px 12px;
    border-radius: 30px;

    &.facebook-icon-wrapper {
      padding: 11px 14px;
    }

    .fa {
      vertical-align: middle;
      color: white;

      &.fa-twitter {
        font-size: 1.1em;
      }
      &.fa-facebook {
        font-size: 1.3em;
      }
      &.fa-linkedin {
        font-size: 1.2em;
      }
    }
  }
}

.blog-item-wrapper, .oia-item-wrapper, .chapter-item-wrapper {
  margin-top: 5em;

  @include breakpoint(medium down){
    margin-top: 1em;
  }

  .nav-back {

    @include breakpoint(medium down){
      margin: 0 0 1em;
    }

    .back-to-blog, .back-to-oia, .back-to-toc {
      font-weight: bold;

      .fa-chevron-left {
        color: get-color(primary);
      }
    }
  }

  a {
    text-decoration: underline;
  }

  .social-wrapper {
    margin-top: 1.5em;

    @include breakpoint(small only){
      margin: 0 0 5em;
    }

    .follow-us-header {
      font-size: 1em;
      text-transform: uppercase;
      font-weight: bold;
      margin-bottom: 1.5em;
    }

    .social-links {
      margin-bottom: 1em;
      background-color:  $steel;
      display: block;
      width: 45px;
      height: 45px;
      padding: 11px 15px;
      border-radius: 30px;

      @include breakpoint(small only){
        display: inline;
        margin-top: 0;
      }

      .fa {
        vertical-align: middle;
        color: white;

        &.fa-twitter {
          font-size: 1.1em;
        }
        &.fa-facebook {
          font-size: 1.3em;
        }
        &.fa-linkedin {
          font-size: 1.2em;
        }
      }
    }
  }

  .download {
    margin-top: 1.5em;

    @include breakpoint(medium down) {
      margin: 3.5em 0;
    }

    a {
      @include secondary-button();

      text-decoration: none;
    }
  }

  .article-title {
    margin-bottom: 22px;
    font-size: 22px;
    font-weight: bold;
    line-height: 28px;
  }

  .author-name {
    margin-bottom: 30px;
    font-size: 15px;
    font-weight: bold;
    line-height: 22px;
    color: $steel;
  }

  .read-more {
    color: $steel;
    font-weight: bold;

    .fa {
      color: $steel;
      font-size: 0.8em;
    }
  }

  .columns.end {
    margin-bottom: 5em;
  }
}


.blog-news-bottom-nav-wrapper {
  border-top: 1px solid #E1DDE8;

  .columns {
    padding: 4em 2em;

    &.middle-line-right {
      border-right: 1px solid #E1DDE8;
    }

    &.middle-line-left {
      border-left: 1px solid #E1DDE8;
    }
  }

  a {
    display: block;
    width: 100%;
    height: 100%;
  }

  .arrow-wrapper {
    width: 10%;
    height: 100%;
    margin: 1.2em 0 0;

    &.arrow-left {
      float: left;
    }

    &.arrow-right {
      float: right;

      .fa {
        float: right;
      }
    }

    .fa-chevron-left, .fa-chevron-right {
      font-size: 2em;
      color: get-color(primary);
    }

    .fa-chevron-left {
      float: left;
    }
  }

  .title-wrapper {
    width: 80%;
    height: 100%;

    &.title-left {
      float: left;
    }

    &.title-right {
      float: right;

      .blog-title {
        text-align: right;
      }
    }

    .nav-label {
      font-weight: bold;
      text-transform: uppercase;
      color: $steel;
      margin-bottom: 0;

      &.previous-label {
        text-align: left;
      }
      &.next-label {
        text-align: right;

        @include breakpoint(small only){
          text-align: left;
        }
      }
    }

    .title {
      font-size: 1.3em;
      font-weight: bold;
    }
  }
}
