.search-wrapper {padding: 50px 0 0 0;}
.view-search-api-search {
	
  .views-exposed-form .views-exposed-widget {float: none;}
  .views-exposed-form label {font-size: 1em;}
  .views-exposed-form input[type=text]{ width:100%; margin: 0 0 .7rem;}
	.views-exposed-form .views-exposed-widget.views-submit-button {float:left;}
	.views-exposed-form .views-exposed-widget.views-submit-button .form-submit {
		margin-top: 0;
    width: auto;
    padding: .7rem 3rem;
    border: 2px solid #f79219;
    border-bottom: 2px solid #f79219;
    font-weight: 700;
    border-radius: 5px;
    background-color: transparent;
    color: #456387;
		cursor:pointer;
	}
	.views-exposed-form .views-exposed-widget.views-submit-button .form-submit:hover {
		color: #fefefe;
		text-decoration: none;
		border: 2px solid #f79219;
		border-bottom: 2px solid #f79219;
		background: #f79219;
	}
	.views-exposed-form .views-exposed-widget.views-reset-button {float:right;}
	.views-exposed-form .views-exposed-widget.views-reset-button .form-submit {
		background: none;
    border: none;
    color: cadetblue;
    padding: .7rem 0;
    margin: 0;
		cursor:pointer;
	}
	.views-exposed-form .views-exposed-widget.views-reset-button .form-submit:hover {
		text-decoration: underline; 
	}
}

.search-wrapper .load-more-posts{
    padding: .7rem 1rem;
    border: 2px solid #f79219;
    border-bottom: 2px solid #f79219;
    font-weight: 700;
    border-radius: 5px;
    color: #1d2e45;
    font-size: 1.2em;
}
