.content-text {
    h2 {
        margin-bottom: 35px;
    }

    p, li {
        color: get-color(secondary);
        /* font-family: "FF Meta Serif W01"; */

        @include breakpoint(large) {
            font-size: 20px;
        }

        &.larger {
            font-size: 20px;

            @include breakpoint(large) {
                font-size: 26px;
            }
        }
    }

    a {
        @include link-styles();
    }

    hr {
        border-bottom: 1px solid $silver;
        margin: 75px 0;
    }
}